
/* Table Display */
.flight-display {
    padding: 0;
    width: 1800px;
    background: var(--medium-blue-bg);
  }
  
  .flight-table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th {
    background: var(--light-blue-bg);
    border-bottom: 1px solid var(--yellow);
    color: var(--yellow);
    font-size: 18px;
    padding: 0px 0;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  tbody td {
    color: var(--font-color-main);
    font-size: 23px;
    text-transform: uppercase;
    text-align: center;
  }

  .flight-table th, .flight-table td {
    padding: 8px;
    text-align: center;
  }
  
  .flight-table th, .flight-table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .logo-container:nth-child(1) {
    margin-right: 10px 0;
    width: 280px;
    text-align: center;
  }
  
  .airline-logo-display {
    max-width: 85px;
    max-height: 50px;
    object-fit: contain;
    padding: 2px;
    margin-left: 8px;
    background-color: white;
    border-radius: 5px;
    transform: scale(0.9);
  }

.flight-logo-banner {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

  .flight-logo-banner .live-banner-container {
    margin: 0;
    opacity: 2;
  }


  /* Show date and time after weather info */
  .flight-logo-banner .flyby-logo {
    animation: fadeIn 15s infinite;
  }
  

  
  .flight-number {
    font-weight: 600;
    margin-left: 8px;
  }
  
  .icon-plane,
  .icon-warning {
    color: var(--light-blue-icon);
    margin-right: 3px;
  }
  
  .custom-runway-data {
    width: 250px;
  }
   .delayed-status {
    background: red;
    border-radius: 5px;
    color: white;
    text-align: center;
    padding: 5px 8px;
    display: inline-block;
    white-space: nowrap;
    border: 1px solid var(--light-blue-bg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

  .flight-status {
    margin: 2px;
  }
  
  .flight-status svg {
    margin-left: 8px;
    color: rgb(241, 238, 238);
  }
  

  @media (max-width: 1200px) {
    .flight-banner-container {
      grid-template-columns: 1fr 4fr 1fr;
    }
  
    .flight-banner-wrapper {
      flex-direction: column;
    }
  
    .flight-display {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .flight-banner-container {
      grid-template-columns: 1fr;
    }
  
    .flight-banner-wrapper {
      padding: 10px;
    }
  
    .flight-display {
      width: 100%;
      padding: 0 10px;
    }
  
    .flight-table th, .flight-table td {
      font-size: 16px;
    }
  }
  