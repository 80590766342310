.weather-widget {
  margin: 0;
  padding: 4px;
  overflow: hidden;
  background-color: var(--dark-blue-bg);
  color: var(--font-color-main);
  line-height: 1.1;
  width: 150px;
  height: 90px;
}
.weather-info h2.test-long-name::after {
  content: "Washington";
}

.weather-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  font-size: 16px;
}

.weather-section {
  margin: 0;
  padding: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

/* Show weather info first */
.weather-content .weather-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  opacity: 2;
  animation: fadeOutIn 25s infinite; /* Cycle every 10 seconds */
}

/* Show date and time after weather info */
.weather-content .date-time {
  animation: fadeIn 25s infinite;
}

/* Keyframes to control the fade in and out of the sections */
@keyframes fadeOutIn {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}

.weather-section {
  transition: opacity 1s ease-in-out, transform 0.5s ease;
}


.widget-icon {
  width: 50px;
  height: 45px;
  margin: 0px;
}

.weather-info h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.2;
  max-width: 140px;
  font-size: clamp(18px, 3vw, 18px);
}

.weather-info h3 {
  color: var(--yellow);
  margin: 5px;
  padding: 3px;
  font-size: 18px;
 
}

/* Date and Time Styling */
.date-time p {
  margin: 3px;
  padding: 3px;
  font-size: 22px;
  color: var(--yellow);
}

.date-time h1 {
  margin: 2px;
  font-size: 22px;
}

.time-period {
  margin: 5px;
  font-size: 18px;
}

.widget-pulse-wrapper {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--banner-red-bg);
  font-weight: bold;
  font-size: 14px;
  gap: 10px;
}

.widget-pulse-wrapper p {
  margin: 0;
  letter-spacing: 3px;
  color: var(--banner-red-bg);
}

